<template>
  <div class="animated fadeIn"  v-loading="loading">
    <CRow>
      
      <el-form ref="queryForm" :model="queryForm" label-width="100px" :inline="true">
        <el-form-item label="所屬單位">
            <el-select v-model="queryForm.organization" filterable placeholder="請選擇" clearable="">
              <el-option
                v-for="item in organizations"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="關鍵字">
          <el-input v-model="queryForm.qstr" placeholder="名稱、備註"></el-input>
        </el-form-item>
        <el-form-item label="顏色">
           <el-checkbox-group v-model="queryForm.type">
              <el-checkbox label="primary" style="color:#409EFF">藍</el-checkbox>
              <el-checkbox label="success" style="color:#67C23A">綠</el-checkbox>
              <el-checkbox label="warning" style="color:#E6A23C">橙</el-checkbox>
              <el-checkbox label="danger" style="color:#F56C6C">紅</el-checkbox>
              <el-checkbox label="info" style="color:#909399">灰</el-checkbox>
           </el-checkbox-group>
        </el-form-item>
        <el-form-item class="ml-5">
          <el-button type="primary" @click="currentPage=1;query(pageSize,0,sort,order,queryForm)">查詢</el-button>
          <el-button type="primary" icon="el-icon-plus" @click="addGroup" v-if="$store.state.auth.role === 'admin' | $store.state.auth.role === 'org-admin'">新增</el-button>
        </el-form-item>
      </el-form>

    </CRow>
    <CRow>
    <el-table
           :cell-class-name="tableCellClassName"
            :data="groups"
            style="width:100%"
            border
            stripe
            empty-text="暫無數據"
            :default-sort = "{prop: 'id', order: 'descending'}"
            @sort-change = "sortChange"
          >
           <el-table-column
              type="index"
              :index="indexFormatter"
              fixed
              >
            </el-table-column>
   
            <el-table-column
                label="名稱"
                sortable = "custom"
                prop="name"
            >
             <template slot-scope="scope">
                     <el-tag effect="dark" :type="scope.row.type">{{scope.row.name}}</el-tag>
             </template>
            </el-table-column>
               <el-table-column
                label="所屬單位"
                prop="organization.name"
            >
            </el-table-column>
            <el-table-column
                label="標籤"
            >
             <template slot-scope="scope">
               <div class="tag_group">   
                     <el-tag v-for="tag in scope.row.groupToGroupTags" :key="tag.tag_id" :type="tag.tag.type">{{tag.tag.name}}</el-tag>
               </div>
             </template>
            </el-table-column>
            <el-table-column
                label="備註"
                prop="memo"
                sortable = "custom"
            >
            </el-table-column>
                           <el-table-column
              fixed="right"
              label="操作"
              width="225">
              <template slot-scope="scope">
                  <el-button
                  @click.native.prevent="approval(scope.row.id)"
                  
                  size="mini">
                  審核
                </el-button>
                <el-button
                v-if="$store.state.auth.role === 'admin' | $store.state.auth.role === 'org-admin'"
                  @click.native.prevent="editGroup(scope.row.id)"
                  
                  size="mini">
                  編輯
                </el-button>
                <el-button
                v-if="$store.state.auth.role === 'admin' | $store.state.auth.role === 'org-admin'"
                  @click.native.prevent="deleteGroup(scope.row.id)"
                  type="danger"
                  size="mini">
                  刪除
                </el-button>
              </template>
          </el-table-column>
                 <el-table-column
                label="生效日期"
                prop="start_date"
                :formatter="dateFormatter"
                width="120px"
                sortable="custom"
            >
            </el-table-column>
            <el-table-column
                label="失效日期"
                prop="end_date"
                :formatter="dateFormatter"
                width="120px"
                sortable="custom"
            >
            </el-table-column>
          </el-table>

    </CRow>
    <CRow>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-sizes="[10, 25, 50]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
    </CRow>

  <el-dialog title="新增群組" :visible.sync="dialogFormVisible" width="1200px" v-loading="loading">
      <el-form :model="form" ref="form" :rules="rules">
         <el-form-item label="所屬單位" :label-width="formLabelWidth" prop="organization">
            <el-select v-model="form.organization" filterable placeholder="請選擇" @change="onOrgChange">
              <el-option
                v-for="item in organizations"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="名稱" :label-width="formLabelWidth" prop="name">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
          <el-form-item label="備註" :label-width="formLabelWidth">
            <el-input type="textarea" v-model="form.memo"></el-input>
        </el-form-item>
          <el-form-item label="顏色" :label-width="formLabelWidth" prop="type">
          <el-radio-group v-model="form.type">
              <el-radio label="primary" style="color:#409EFF">藍</el-radio>
              <el-radio label="success" style="color:#67C23A">綠</el-radio>
              <el-radio label="warning" style="color:#E6A23C">橙</el-radio>
              <el-radio label="danger" style="color:#F56C6C">紅</el-radio>
              <el-radio label="info" style="color:#909399">灰</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="標籤" class="tags" :label-width="formLabelWidth" prop="tags">
          <div style="all:initial;">
              <el-transfer
                v-model="form.tags"
                filterable
                :titles="['候選', '已加入']"
                :props="{
                  key: 'tag_id',
                  label: 'name'
                }"
                :data="tagExps">
                <span slot-scope="{ option }"><el-tag :type="option.type">{{ option.name }}</el-tag>
                <el-switch
                    v-model="option.need_approve"
                    active-text="需審核"
                    inactive-text="直接下載">
                    </el-switch></span>
              </el-transfer>
          </div>
              <el-form-item label="生效日期" :label-width="formLabelWidth" prop="start_date">
          <el-date-picker type="date" value-format="timestamp" placeholder="選擇日期"  v-model="form.start_date"></el-date-picker>
        </el-form-item>
        <el-form-item label="失效日期" :label-width="formLabelWidth" prop="end_date">
          <el-date-picker type="date" value-format="timestamp" placeholder="選擇日期"  v-model="form.end_date"></el-date-picker>
        </el-form-item>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">確 定</el-button>
      </div>
  </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Vue from 'vue'
import moment from "moment-timezone";
import API from '@/api/'
Vue.filter('converDate',function(value){
  if(value){
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment(value).tz(tz).format('YYYY-MM-DD');
  }else{
    return value;
  }

})
export default {
  name: 'GroupList',
  created () {
      this.$store.dispatch('organization/list', {max: 1000, offset: 0}, {
          root: true
      });
      this.$store.dispatch('group/list', {max: this.max, offset: this.offset, sort: this.sort, order: this.order, showExpired: true}, {
          root: true
      });
  },
  data() {
        return {
          loading: false,
          pageSize:10,
          currentPage:0,
          queryForm: {
            qstr:'',
            type:[],
            organization: []
          },
          form: {
            id: null,
            name:'',
            memo:'',
            type:'',
            tags:[],
            organization: '',
            start_date:null,
            end_date:null,
          },
          dialogFormVisible: false,
          formLabelWidth: '120px',
          rules: {
            organization: [
              { required: true, message: '請選擇所屬單位', trigger: 'blur' },
            ],
            name: [
              { required: true, message: '請輸入名稱', trigger: 'blur' },
            ],
            start_date: [
              { required: true, message: '請輸生效日期', trigger: 'blur' },
            ],
            type: [
              { required: true, message: '請選擇顏色', trigger: 'blur' },
            ]
          },
          tagExps: [],
          tags: [],
        }
  },
  methods:{
    onOrgChange(selectedValue) {
      this.tags = this.getOrganizationById(selectedValue).tags;
      this.form.tags = [];
          this.tagExps = this.tags.map(t=>{
            return {tag_id:t.id, name: t.name ,type: t.type, need_approve: false}
        })
    },
    tableRowClassName({row, rowIndex}) {
        const now = new Date().getTime();
        if (row.start_date > now || row.end_date < now) {
          return 'warning-row';
        } 
        return '';
      },
    tableCellClassName({row, column, rowIndex, columnIndex}) {
        const now = new Date().getTime();
        if(column.property === 'start_date'){
          if (row.start_date > now) {
            return 'warning-cell';
          } 
        }
        if(column.property === 'end_date'){
          if(row.end_date < now){
             return 'warning-cell';
          }
        }
        return '';
      },
    addGroup() {
      if(this.$refs.form) {
        this.$refs.form.resetFields();
      }
      this.form.id=null;
      this.form.name='';
      this.form.memo='';
      this.form.organization='';
      this.form.type=null;
      this.form.tags=[];
      this.form.start_date = moment().startOf('day').toDate().getTime();
      this.form.end_date= null;
        this.$store.dispatch('tag/list', {max: 1000, offset: 0}, {
            root: true
        }).then(res=>{
            this.tagExps = this.tags.map(t=>{
            return {tag_id:t.id, name: t.name ,type: t.type, need_approve: false}
            })
        });
      this.dialogFormVisible = true;
    },
    approval(id) {
      this.$router.push({ path: `/group/group-audio-approval/${id}` })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.query(val,this.offset,this.sort,this.order,this.queryForm);
      },
      handleCurrentChange(val) {
        this.query(this.pageSize,this.pageSize*(val-1),this.sort,this.order,this.queryForm);

      },
    query(max,offset, sort, order, params = {}) {
      this.$store.dispatch('group/list', {max: max, offset: offset, sort: sort, order: order, showExpired: true, ...params}, {
          root: true
      });
    },
    indexFormatter(index){
     return `#${parseInt(this.offset)+ index + 1}`;

    },
    dateFormatter(row, column, cellValue){
      if(cellValue){
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

        return moment(parseInt(cellValue)).tz(tz).format('YYYY-MM-DD');
        //return moment(cellValue).format('YYYY-MM-DD');
      }else{
        return cellValue;
      }

    },
     editGroup(group_id) {
      if (this.$refs['form']) {
        this.$refs['form'].resetFields();
      }
       const data = this.getGroupById(group_id);
      
      this.$store.dispatch('tag/list', {max: 1000, offset: 0}, {
          root: true
      }).then(res=>{

        if(data.organization) {
          this.tags = this.getOrganizationById(data.organization.id)? this.getOrganizationById(data.organization.id).tags:[];
          this.form.organization = data.organization.id;
          
        } else {
          this.tags = [];
          this.form.organization = null;
        }
        this.tagExps = this.tags.map(t=>{
            return {tag_id:t.id, name: t.name ,type: t.type, need_approve: false}
        })
         this.form.id=data.id;
        this.form.name=data.name;
        this.form.memo=data.memo;
        this.form.type=data.type;
        this.form.start_date= data.start_date;
        this.form.end_date= data.end_date;
        this.form.organization = data.organization.id;
        this.form.tags=data.groupToGroupTags.map(tag=>tag.tag_id);
        for (const tag of data.groupToGroupTags) {
          const tagExp = this.tagExps.find(t=>t.tag_id == tag.tag_id);
          if(tagExp){
              tagExp.need_approve = tag.need_approve;
          }
          
        }
      this.dialogFormVisible = true;
        
      });
      
       
    },
    deleteGroup(tag_id) {
      this.$confirm('此操作將永久刪除該標籤, 是否繼續?', '提示', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$store.dispatch('group/remove', tag_id , {
            root: true
          }).then( res => {
            this.query(this.pageSize,this.offset,this.sort,this.order,this.queryForm)
            this.$message({
            type: 'success',
            message: '删除成功!'
          });
          })
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });

    },
    submit() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
              if(this.form.id) {
                this.submitUpdate();
              } else {
                this.submitAdd();
              }
          } else {
            return false;
          }
        });
    },
    submitAdd() {
      this.loading = true;
      const groupToGroupTags = [];
      for(const row of this.tagExps) {
          if (this.form.tags.indexOf(row.tag_id) > -1) {
              groupToGroupTags.push(row);
          }
      }
      this.$store.dispatch('group/add', {...this.form, groupToGroupTags} , {
          root: true
      }).then( res => {
        this.dialogFormVisible = false;
        this.form.id=null;
        this.form.name='';
        this.form.memo='';
        this.form.type='';
        this.form.start_date= null;
        this.form.end_date= null;
        this.form.tags = [];
        this.loading = false;
        this.query(this.pageSize,this.offset,this.sort,this.order,this.queryForm)
      })
    },
    submitUpdate() {
      this.loading = true;
      const groupToGroupTags = [];
      for(const row of this.tagExps) {
          if (this.form.tags.indexOf(row.tag_id) > -1) {
              groupToGroupTags.push(row);
          }
      }
      this.$store.dispatch('group/update', {...this.form, groupToGroupTags} , {
          root: true
      }).then( res => {
        this.dialogFormVisible = false;
        this.form.id=null;
        this.form.name='';
        this.form.memo='';
        this.form.type='';
        this.form.tags = [];
        this.loading = false;
        this.query(this.pageSize,this.offset,this.sort,this.order,this.queryForm)
      })
    },
    sortChange(args) {
      if (args.order === null) {
        this.$store.commit('group/SET_SORT','id',{root: true})
        this.$store.commit('group/SET_ORDER','ascending',{root: true})
      } else {
        this.$store.commit('group/SET_SORT',args.prop,{root: true})
        this.$store.commit('group/SET_ORDER',args.order,{root: true})
      }
      
      this.query(this.pageSize,this.offset,this.sort,this.order,this.queryForm)
    },
  },
  computed: {
      ...mapGetters({
        // 取得資料夾列表
        groups: 'group/groups',
        total:'group/total',
        max:'group/max',
        offset:'group/offset',
        getGroupById: 'group/getGroupById',
        sort: 'group/sort',
        order: 'group/order',
        organizations: 'organization/organizations',
        getOrganizationById: 'organization/getOrganizationById',
      })
  },
}
</script>
<style scoped>
  .el-table >>> .el-table__body tr.warning-row td{
    background: oldlace;
  }
  .el-table >>> .el-table__body td.warning-cell{
    color:#F56C6C;
  }
  .tag_group .el-tag+.el-tag {
    margin-left: 10px;
  }
  .el-transfer >>> .el-transfer-panel, .el-cascader {
    width: 420px;
  }
</style>
